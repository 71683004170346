import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Stack,
  Skeleton,
  useMediaQuery,
  Typography,
} from "@mui/material";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Loader from "components/Loader";

function Carousel({ loading, color = "white", children }) {

  children = Array.isArray(children) ? children : [children];

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLg = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const [currentIndex, setCurrentIndex] = useState(0);

  const itemsPerView = () => {
    if (isXs) return 1;
    if (isSm) return 2;
    if (isMd) return 3;
    if (isLg) return 4;
    return 5;
  }

  const shouldRender = (index) => {
    return currentIndex <= index && index < currentIndex + itemsPerView();
  }

  const renderChildren = () => {
    if (loading) {
      return (
        <div
          style={{
            width: "200px",
            height: "200px",
          }}
        >
          <Loader message="Loading galleries" />
        </div>
      );
    }
    return children.map((child, index) => {
      if (!shouldRender(index)) return null;
      return (
        <div
          key={index}
          style={{
            width: "200px",
            height: "200px",
          }}
        >
          {child}
        </div>
      );
    });
  }

  const hasPrev = () => (
    !loading && currentIndex > 0
  );

  const hasNext = () => (
    !loading && currentIndex + itemsPerView() < children.length
  );

  return (
    <Stack
      direction="row"
      spacing={2}
    >
      <IconButton
        disabled={!hasPrev()}
        sx={{
          opacity: hasPrev() ? 1 : 0,
        }}
        onClick={() => setCurrentIndex(Math.max(currentIndex - 1, 0))}
      >
        <ArrowCircleLeftIcon
          sx={{ color }}
          fontSize="large"
        />
      </IconButton>
      {renderChildren()}
      <IconButton
        disabled={!hasNext()}
        sx={{
          opacity: hasNext() ? 1 : 0,
        }}
        onClick={() => setCurrentIndex(Math.min(currentIndex + 1, children.length - 1))}
      >
        <ArrowCircleRightIcon
          sx={{ color }}
          fontSize="large"
        />
      </IconButton>
    </Stack>
  )
}

Carousel.propTypes = {}

export default Carousel
