import React, { useRef } from "react";
import "galleries/default/DefaultHeader.css";
import {
  Grid,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import ScreenScalingImage from "components/ScreenScalingImage";

export default function DefaultHeader( { photo, tagline } ) {

  const imageContainerRef = useRef();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("md"));

  if (isSm) {
    return (
      <Grid container className="header-container-small">
        <Grid 
          item 
          xs={12} 
          className="header-photo-small" 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
          sx={{
            backgroundImage: `url(${photo.url})`,
          }}
        >
          <div className="header-tagline-small">
            {tagline}
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid 
      container 
      className="header-container"
    >
      <Grid xs={4} item sx={{ border: "0px solid red" }} display="flex" alignItems="center" justifyContent="flex-end">
        <div className="header-tagline">
          {tagline}
        </div>
      </Grid>
      <Grid 
        xs={8} 
        item 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
      >
        <div 
          ref={imageContainerRef}
          style={{
            height: "80%",
            width: "80%",
          }}
        >
          <ScreenScalingImage 
            imageURL={photo.url}
            parentRef={imageContainerRef}
            imageBoxClassName="header-photo-container"
          />
        </div>
      </Grid>
    </Grid>
  );

}
