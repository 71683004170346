import React from "react";
import {
  Tooltip,
  IconButton,
} from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';


export default function InstagramButton({color, tooltip}) {
  return (
    <Tooltip title={tooltip ? "@fran.a.photo" : ""}>
      <IconButton
        onClick={() => window.open("https://www.instagram.com/fran.a.photo", "_blank")}
      >
        <InstagramIcon sx={{ color: color }} fontSize="large" />
      </IconButton>
    </Tooltip>
  )
}
