import React, { useState, useEffect } from "react";

export default function PhotoGallery({ photos, numColumns, gapPx, onClick }) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  const imageWidth = () => {
    return windowWidth / numColumns;
  }

  function array(n) {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(i);
    }
    return result;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      {array(numColumns).map((colIndex) => (
        <div
          key={`col-${colIndex}`}
          style={{
            display: "flex",
            flexDirection: "column",
            width: imageWidth(),
          }}
        >
          {photos.map((photo, photoIndex) => {
            if (photoIndex % numColumns !== colIndex) return null;
            return (
              <img
                key={photo.id}
                src={photo.url}
                style={{
                  width: `calc(100% - ${colIndex === 0 ? 0 : gapPx}px)`,
                  marginBottom: gapPx,
                  marginLeft: colIndex === 0 ? 0 : gapPx,
                }}
                onClick={() => onClick(photoIndex)}
                loading="lazy"
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}
