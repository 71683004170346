import { useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { snapshotToArray } from "firebase-app";
import { useDispatch } from "react-redux";
import { collectionsActions } from "store/collections";
import PropTypes from "prop-types";


function CollectionLoader(props) {

  const collectionName = props.collectionName;
  const q = props.query;

  const dispatch = useDispatch();
  const [snapshot, loading, error] = useCollection(q);

  useEffect(() => {
    dispatch(collectionsActions.setCollectionState({
      collectionName,
      loading,
    }));
  }, [loading, dispatch]);

  useEffect(() => {
    dispatch(collectionsActions.setCollectionState({
      collectionName,
      error: error || "",
    }));
  }, [error, dispatch]);

  useEffect(() => {
    if(snapshot) {
      const data = snapshotToArray(snapshot);
      dispatch(collectionsActions.setCollectionState({
        collectionName,
        data,
      }));
    }
  }, [snapshot, dispatch]);

  return null;

}

CollectionLoader.propTypes = {
  collectionName: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  resultProcessing: PropTypes.func,
}

CollectionLoader.defaultProps = {
  resultProcessing: (data) => data,
}

export default CollectionLoader;
