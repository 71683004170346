import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  photos: [],
  photoIndex: 0,
}

const photoViewerSlice = createSlice({
  name: "photoViewer",
  initialState: initialState,
  reducers: {
    close(state) {
      state.isOpen = false;
    },
    open(state, action) {
      state.isOpen = true;
      state.photos = action.payload.photos;
      state.photoIndex = action.payload.photoIndex;
    },
    toggle(state) {
      state.isOpen = !state.isOpen;
    },
    goToPrevious(state) {
      state.photoIndex = Math.max(0, state.photoIndex - 1);
    },
    goToNext(state) {
      state.photoIndex = Math.min(state.photos.length - 1, state.photoIndex + 1);
    },
  }
});

export const photoViewerActions = photoViewerSlice.actions;

export default photoViewerSlice.reducer;