import { configureStore } from "@reduxjs/toolkit";
import userReducer from "store/user";
import menuReducer from "store/menu";
import collectionsSlice from "./collections";
import photoViewerSlice from "./photoViewer";
import scrollSlice from "./scroll";

const store = configureStore({
  reducer: {
    user: userReducer,
    menu: menuReducer,
    collections: collectionsSlice,
    photoViewer: photoViewerSlice,
    scroll: scrollSlice,
  }
});

export default store;