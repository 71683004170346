const firebaseConfig = {
  apiKey: "AIzaSyDVTQTbPtxBazqQDgaLBgNRKe-IlrOeEhk",
  authDomain: "fran-a-photo.firebaseapp.com",
  projectId: "fran-a-photo",
  storageBucket: "fran-a-photo.appspot.com",
  messagingSenderId: "507987978886",
  appId: "1:507987978886:web:1efd27793e5cc6ae3eff07",
  measurementId: "G-C3WHQ9GR1H"
};

export default firebaseConfig;