import React from "react";
import {
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useMediaQuery } from '@mui/material';
import InstagramButton from "./InstagramButton";
import { photoViewerActions } from "store/photoViewer";
import { useDispatch } from "react-redux";

export default function MotionImage({ photos, photo, index }) {

  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));


  const renderInfo = () => {
    return (
      <Stack
        direction="row"
      >
        <InstagramButton color="white" />

        <Tooltip title={photo.location}>
          <IconButton
            onClick={() => window.open(`https://www.google.com/maps?q=${photo.lat},${photo.lng}`, "_blank")}
          >
            <LocationOnIcon sx={{ color: "white" }} />
          </IconButton>
        </Tooltip>

      </Stack>
    )
  }

  const renderText = () => {
    return (
      <Stack
        justifyContent="center"
        spacing={2}
        sx={{
          width: "60%",
          borderWidth: `0 ${index % 2}px 1px ${1 - index % 2}px`,
          borderColor: "white",
          borderStyle: "dashed",
        }}
        p={2}
      >
        <Typography
          color="white"
          textTransform="uppercase"
          fontFamily="'Archivo Black', sans-serif"
          fontSize="2rem"
        >
          {photo.name}
        </Typography>
        <Typography
          color="white"
          textTransform="uppercase"
          fontFamily="'Roboto', sans-serif"
          fontSize="1rem"
        >
          {photo.description}
        </Typography>
        {renderInfo()}
      </Stack>
    );
  }

  const handlePhotoClick = () => {
    dispatch(photoViewerActions.open({
      photoIndex: index,
      photos,
    }));
  }

  const renderPhoto = () => {
    return (
      <img
        className="motion-image"
        onClick={handlePhotoClick}
        style={{
          border: isSmallScreen ? "5px solid white" : "20px solid white",
          maxHeight: isSmallScreen ? "90%": "70%",
          maxWidth: isSmallScreen ? "90%": "70%",
        }}
        src={photo.url}
        alt=""
      />
    );
  }

  const renderLeftTop = () => {
    if (isSmallScreen) {
      return renderPhoto();
    }
    return index % 2 === 0 ? renderText() : renderPhoto();
  }

  const renderRightBottom = () => {
    if (isSmallScreen) {
      return renderText();
    }
    return index % 2 === 0 ? renderPhoto() : renderText();
  }

  const sx = {
    backgroundColor: "black",
    zIndex: "1",
    position: "relative",
  };

  if(!isSmallScreen) {
    sx.width = "100vw";
    sx.height = "100vh";
  }

  return (
    <Grid
      container
      sx={sx}
    >
      <Grid
        item
        xs={12}
        md={photo.portrait ? 6 : index % 2 === 0 ? 4 : 8}
        display="flex" 
        justifyContent="center" 
        alignItems="center"

      >
        {renderLeftTop()}
      </Grid>
      <Grid
        item
        xs={12}
        md={photo.portrait ? 6 : index % 2 === 0 ? 8 : 4}
        display="flex" 
        justifyContent="center" 
        alignItems="center"
        mb={2}
      >
        {renderRightBottom()}
      </Grid>
    </Grid>
  )
}
