import { createSlice } from "@reduxjs/toolkit";

const defaultCollectionState = {
  loading: true,
  error: "",
  data: [],
};

const initialState = {
  portrait: {...defaultCollectionState},
  galleries: {...defaultCollectionState},
  photos: {...defaultCollectionState},
  categories: {...defaultCollectionState},
}

const buildIdMap = (data) => {
  if(!data) return {};
  const idMap = {};
  for(let i = 0; i < data.length; i++) {
    idMap[data[i].id] = data[i];
  }
  return idMap;
}

const collections = createSlice({
  name: "collections",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setCollectionState(state, action) {
      const { collectionName, ...collectionState } = action.payload;
      if(!state[collectionName]) {
        state[collectionName] = {...defaultCollectionState};
      }
      const data = collectionState.data;
      state[collectionName] = {
        ...state[collectionName], 
        ...collectionState,
        idMap: buildIdMap(data),
      };
    },
  }
});

export const collectionsActions = collections.actions;

export default collections.reducer;