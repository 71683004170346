import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  target: null,
}

const scrollSlice = createSlice({
  name: "scroll",
  initialState: initialState,
  reducers: {
    clearTarget() {
      return initialState;
    },
    setTarget(state, action) {
      state.target = action.payload;
    }
  }
});

export const scrollActions = scrollSlice.actions;

export default scrollSlice.reducer;