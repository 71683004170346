import { useEffect, useState } from "react";

function preloadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}

export default function useImagePreloader(imageURLList) {

  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {

      if (isCancelled) {
        return;
      }

      const imagesPromiseList = [];
      for (const url of imageURLList) {
        imagesPromiseList.push(preloadImage(url));
      }
  
      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    }
  }, [imageURLList])

  return { imagesPreloaded };
}
