import React, { useState } from "react";
import {
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import useInterval from "hooks/useInterval";
import InstagramButton from "components/InstagramButton";
import Me from "images/Me";
import { categories as categoryData } from "data";

export default function LandingSection() {

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [currentIndex, setCurrentIndex] = useState(0);
  const [stop, setStop] = useState(false);

  let categories = categoryData;
  categories = categories.filter((category) => category.name !== "all");
  const currentCategory = categories[currentIndex];

  useInterval(() => {
    if (!stop) {
      setCurrentIndex((currentIndex + 1) % categories.length);
    }
  }, 4000);

  const handleMouseEnter = (index) => {
    if (!isXs) {
      setStop(true);
    }
    setCurrentIndex(index);
  }

  const handleMouseLeave = () => {
    setStop(false);
  }

  const renderHeader = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        py={2}
        width="100%"
        minHeight="80px"
      >
        <Stack
          alignItems="center"
          justifyContent="center"

        >
          <Me />
          <Stack
            position="relative"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            bottom={170}
          >
            <Typography
              textAlign="center"
              color="white"
              textTransform="uppercase"
              fontSize="2rem"
              sx={{
                fontFamily: "'Anton', sans-serif",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              François Aubry
            </Typography>
            <Typography
              textAlign="center"
              color="white"
              textTransform="uppercase"
              fontSize="2rem"
              sx={{
                fontFamily: "'Anton', sans-serif",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              Photographer
            </Typography>
          </Stack>

        </Stack>
      </Stack>
    );
  }

  const renderFooter = () => {
    return (
      <Stack
        bottom={0}
        direction="row"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          backgroundColor={isXs ? "" : "rgba(0, 0, 0, 0.5)"}
          p={3}
          alignItems="center"
          justifyContent="center"
        >
          <InstagramButton
            color="white"
            tooltip={false}
          />
          <Typography
            fontFamily="'Anton', sans-serif"
            color="white"
          >
            @fran.a.photo
          </Typography>

        </Stack>
      </Stack>
    );
  }

  const renderMain = () => {
    return (
      <Stack
        direction="column"
        alignItems="flex-end"
        justifyContent="center"
        spacing={2}
        height="100%"
      >

        {categories.map((category, index) => (
          <Typography
            textTransform="capitalize"
            width="200px"
            key={category.name}
            fontFamily={"'Anton', sans-serif"}
            color="black"
            backgroundColor={index === currentIndex ? "white" : "rgba(255, 255, 255, 0.5)"}
            fontSize={isXs ? "20px" : "30px"}
            paddingLeft="20px"
            paddingRight="20px"
            //onMouseEnter={() => handleMouseEnter(index)}
            //onMouseLeave={handleMouseLeave}
            style={{
              userSelect: "none",
            }}
          >
            {category.name}
          </Typography>
        ))}
      </Stack>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url('${isXs ? currentCategory.imageURLMobile : currentCategory.imageURL}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        transition: "background-image 2s ease-in-out",
      }}
    >
      <Grid
        container
        height="100%"
      >
        {!isXs && (
          <Grid
            item
            xs={12}
            height="25%"
          >
            {renderHeader()}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          height={isXs ? "75%" : "50%"}
        >
          {renderMain()}
        </Grid>
        <Grid
          item
          xs={12}
          height="25%"
        >
          {renderFooter()}
        </Grid>
      </Grid>
    </div>
  );
}
