import React from "react";
import "VSlideGallery.css";

export default function VSlideContainer({ children }) {

  return (
    <div className="v-slide-gallery">
      {children.map((child, index) => (
        <div key={`slide ${index}`} className="slide">
          {child}
        </div>
      ))}
    </div>
  );
}
