import React from "react";
import {
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function CalleryCard( { gallery } ) {

  const navigate = useNavigate();

  return (
    <Stack
      justifyContent="center"
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url('${gallery.photos[0].url}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        borderRadius: "8px",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/galleries/${gallery.id}`)}
    >
      <Typography
        textAlign="center"
        fontFamily="'Anton', sans-serif"
        backgroundColor="rgba(255, 255, 255, 0.7)"
        py={2}
        style={{
          userSelect: "none",
        }}
      >
        {gallery.name}
      </Typography>
    </Stack>
  );
}
