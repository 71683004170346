import React, { useState } from "react";
import {
  ImageList,
  useMediaQuery,
  ImageListItem,
  Typography,
  Stack,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import { filterByCategory } from "utils/photos";
import { useDispatch } from "react-redux";
import { photoViewerActions } from "store/photoViewer";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CategoryIcon from '@mui/icons-material/Category';
import { sortByCategory } from "utils/photos";
import PhotoGallery from "components/PhotoGallery";
import DefaultHeader from "galleries/default/DefaultHeader";

export default function DefaultGallery() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const params = useParams();

  const photosCollection = useSelector((state) => state.collections.photos);

  const categoriesCollection = useSelector((state) => state.collections.categories);

  const [hoverIndex, setHoverIndex] = useState();
  const [showCategories, setShowCategories] = useState(false);

  if (photosCollection.loading) {
    return (
      <Stack
        width="100wh"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <Loader message="Loading photos" />
      </Stack>
    );
  }

  const numCols = () => {
    if (isSm) return 1;
    if (isMd) return 2;
    return 3;
  }

  const photos = sortByCategory(filterByCategory(photosCollection.data, params.categoryName), params.categoryName);
  const categories = ["all", ...new Set(photosCollection.data.map((photo) => photo.category))].sort();

  if (photos.length === 0) {
    return (
      <Stack
        width="100wh"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          textAlign="center"
          color="white"
          fontFamily={"'Anton', sans-serif"}
          fontSize="2rem"
        >
          There are no photos in the <b>{params.categoryName}</b> category
        </Typography>
      </Stack>
    )
  }

  const handleClick = (photoIndex) => {
    dispatch(photoViewerActions.open({
      photoIndex,
      photos,
    }));
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getTagline = () => {
    console.log(categoriesCollection.idMap);
    if(categoriesCollection.loading) return "";
    return categoriesCollection.idMap[params.categoryName].tagline;
  }

  return (
    <Box>
      <Stack
        position="fixed"
        top={0}
        right={0}
        zIndex={1}
        alignItems="flex-end"
        justifyContent="center"
      >
        <Stack
          direction="row"
        >
          <IconButton
            onClick={scrollToTop}
          >
            <ArrowCircleUpIcon
              fontSize="large"
              sx={{ color: "#41EAC1" }}
            />
          </IconButton>
          <IconButton
            onClick={scrollToBottom}
          >
            <ArrowCircleDownIcon
              fontSize="large"
              sx={{ color: "#41EAC1" }}
            />
          </IconButton>
          <IconButton
            onClick={() => setShowCategories(!showCategories)}
          >
            <CategoryIcon
              fontSize="large"
              sx={{ color: "#41EAC1" }}
            />
          </IconButton>
        </Stack>

        <List>
          {categories.map((category, index) => (
            <ListItem
              key={category}
              disablePadding
              style={{
                right: showCategories ? "0" : "-100%",
                transition: `right ${0.3 + index / categories.length}s ease-in-out`,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ListItemButton
                onClick={() => {
                  setShowCategories(false);
                  navigate(`/category/${category}`);
                }}
              >
                <ListItemText primary={(
                  <Typography
                    textTransform="capitalize"
                    textAlign="right"
                    fontFamily="'Anton', sans-serif"
                    color={category === params.categoryName ? "white" : "#41EAC1"}
                  >
                    {category}
                  </Typography>
                )} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
      <DefaultHeader
        tagline={getTagline()}
        photo={photos[0]}
      />
      <PhotoGallery
        photos={photos.filter((photo, index) => index > 0)}
        numColumns={numCols()}
        gapPx={8}
        onClick={(index) => handleClick(index)}
      />
    </Box>

  );

}
