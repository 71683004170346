import React from "react";
import {
  CircularProgress,
  Typography,
  Stack,
} from "@mui/material";

export default function Loader({message}) {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <CircularProgress sx={{color: "white"}}/>
      <Typography
        color="white"
        fontFamily="'Anton', sans-serif"
        variant="caption"
        textTransform="uppercase"
      >
        {message}
      </Typography>
    </Stack>
  )
}
