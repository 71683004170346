import React from "react";
import me from "images/me.svg";

export default function Me() {

  return (
    <img src={me} alt=""/>
  );

}
