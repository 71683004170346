import React from "react";
import process from "images/process.svg";

export default function Process({style}) {

  return (
    <img src={process} alt="" style={style}/>
  );

}
