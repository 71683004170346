export const categories = [
  {
    name: "all",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/all.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/all_mobile.jpg",
  },
  {
    name: "performances",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/performances.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/performances_mobile.jpg",
  },
  {
    name: "landscape",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/landscape.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/landscape_mobile.jpg",
  },
  {
    name: "culture",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/culture.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/culture_mobile.jpg",
  },
  {
    name: "street",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/street.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/street_mobile.jpg",
  },
  {
    name: "portrait",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/portrait.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/portrait_mobile.jpg",
  },
  {
    name: "events",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/events.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/events_mobile.jpg",
  },
  {
    name: "artistic",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/artistic.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/artistic_mobile.jpg",
  },
  {
    name: "nature",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/nature.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/nature_mobile.jpg",
  },
  {
    name: "cityscape",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/cityscape.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/cityscape_mobile.jpg",
  },
  {
    name: "life",
    imageURL: process.env.PUBLIC_URL + "/photos/landing/life.jpg",
    imageURLMobile: process.env.PUBLIC_URL + "/photos/landing/life_mobile.jpg",
  },
];
