import "App.css";
import Auth from "views/Auth";
import { Route, Routes } from "react-router-dom"
import Home from "views/Home";
import { useSelector } from 'react-redux';
import HScrollGallery from "components/HScrollGallery";
import VSlideGallery from "components/VSlideGallery";
import VHalfHalfGallery from "components/VHalfHalfGallery";
import GalleryList from "components/GalleryList";
import BurgerIcon from "components/BurgerIcon";
import Menu from "components/Menu";
import Gallery from "components/Gallery";
import MotionBlur from "components/MotionBlur";
import PortraitGallery from "galleries/portrait/PortraitGallery";
import { query, collection, where, doc } from "firebase/firestore";
import { db } from "firebase-app";
import CollectionLoader from "components/CollectionLoader";
import PhotoViewer from "components/PhotoViewer";
import HomeView from "views/HomeView";
import DefaultGallery from "galleries/default/DefaultGallery";
import useImagePreloader from "hooks/useImagesPreloaded";
import { categories } from "data";

function App() {

  const user = useSelector((state) => state.user);

  useImagePreloader(categories.map((category) => category.imageURL));
  useImagePreloader(categories.map((category) => category.imageURLMobile));

  return (
    <>
      <BurgerIcon />
      <Menu />
      <PhotoViewer />
      <CollectionLoader
        collectionName="portrait"
        query={query(collection(doc(collection(db, "galleries"), "portrait"), "photos"))}
      />
      <CollectionLoader
        collectionName="galleries"
        query={query(collection(db, "galleries"))}
      />
      <CollectionLoader
        collectionName="photos"
        query={query(collection(db, "photos"))}
      />
       <CollectionLoader
        collectionName="categories"
        query={query(collection(db, "categories"))}
      />
      <Routes>
        <Route path="/" element={<HomeView />} />
        {/* 
        <Route path="/category/portrait" element={<PortraitGallery />} />
        */}
        <Route path="/category/:categoryName" element={<DefaultGallery />} />
        <Route path="/galleries/motion" element={<MotionBlur />} />
        {/*
          <Route path="/halfhalf" element={<VHalfHalfGallery />} />
          <Route path="/slides" element={<VSlideGallery />} />
          <Route path="/horizontal" element={<HScrollGallery />} />
          <Route path="/auth" element={<Auth />} />
        */}

      </Routes>
    </>
  );
}

export default App;
