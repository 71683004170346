import React from "react";
import {
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import Carousel from "./Carousel";
import CategoryCard from "components/CategoryCard";
import GalleryCard from "components/GalleryCard";
import { useSelector } from "react-redux";
import { categories } from "data";

export default function PhotosSection() {

  const galleries = useSelector((state) => state.collections.galleries);

  const displayCategoreis = categories.filter((category) => category.name !== "artistic");

  return (
    <Grid
      container
      height="100%"
    >
      <Grid
        item
        xs={12}
        height="50%"
      >
        <Stack
          justifyContent="space-evenly"
          alignItems="center"
          height="100%"
        >
          <Typography
            textAlign="center"
            color="white"
            fontFamily="'Anton', sans-serif"
            fontSize="1.6rem"
          >
            Photos by category
          </Typography>
          <Carousel>
            {displayCategoreis.map((category) => (
              <CategoryCard 
                key={category.name} 
                category={category}
              />
            ))}
          </Carousel>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        height="50%"
        backgroundColor="white"
        borderTop="4px dotted black"
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          height="100%"
          spacing={4}
        >
          <Stack>
            <Typography
              textAlign="center"
              color="black"
              fontFamily="'Anton', sans-serif"
              fontSize="1.6rem"
            >
              Galleries
            </Typography>
          </Stack>
          <Carousel
            color="black"
            loading={galleries.loading}
          >
            {galleries.data.map((gallery) => (
              <GalleryCard key={gallery.id} gallery={gallery} />
            ))}
          </Carousel>
        </Stack>
      </Grid>
    </Grid>
  )
}
