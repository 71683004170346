import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Stack,
  Box,
  useMediaQuery,
} from "@mui/material";
import Carousel from "./Carousel";
import CategoryCard from "components/CategoryCard";
import GalleryCard from "components/GalleryCard";
import { useSelector } from "react-redux";
import { categories } from "data";
import Nikon from "images/Nikon";
import Process from "images/Process";

export default function AboutSection() {

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      height="100%"
    >
      <Stack
        width="100%"
        py={2}
        px={isXs ? 6 : 8}
        alignItems="center"
        justifyContent="center"
        spacing={6}
      >
        <Typography
          textAlign="center"
          color="white"
          fontFamily="'Anton', sans-serif"
          fontSize={isXs ? "2rem" : "3rem"}
        >
          A little bit about me
        </Typography>
        <Typography
          textAlign="justify"
          color="white"
          fontFamily="'Oswald', sans-serif"
          fontSize={isXs ? "1rem" : "1.5rem"}

        >
          Self-taught photographer in love with all forms of photography.
        </Typography>
        <Stack>
          <Typography
            textAlign="center"
            color="white"
            fontFamily="'Anton', sans-serif"
            fontSize={isXs ? "1rem" : "1.5rem"}
          >
            How did I end up here?
          </Typography>
          <Typography
            textAlign="justify"
            color="white"
            fontFamily="'Oswald', sans-serif"
            fontSize={isXs ? "1rem" : "1.5rem"}
          >
            Did a PhD in computer science with the goal of
            becoming a university professor, found out it's not for me and
            ended up as a software engineer. Recently quit my job to focus
            all my energy into photography.
          </Typography>
        </Stack>
        <Process style={{ height: isXs ? "" : "40%", maxWidth: "70%" }} />
        <Stack>
          <Typography
            textAlign="center"
            color="white"
            fontFamily="'Anton', sans-serif"
            fontSize={isXs ? "1rem" : "1.5rem"}
          >
            My motto
          </Typography>
          <Typography
            textAlign="justify"
            color="white"
            fontFamily="'Oswald', sans-serif"
            fontSize={isXs ? "1rem" : "1.5rem"}
          >
            In case of doubt, go out and take photos.
          </Typography>
        </Stack>

      </Stack>

    </Grid>
  )
}
