import React, { useEffect, useRef } from "react";
import {
  Dialog,
  Stack,
  Grid,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { photoViewerActions } from "store/photoViewer";
import CloseIcon from "@mui/icons-material/Close";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useMediaQuery } from "@mui/material";
import ScreenScalingImage from "./ScreenScalingImage";

export default function PhotoViewer() {

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const img = document.getElementById("photo");

  const dispatch = useDispatch();

  const imageContainerRef = useRef(null);

  const photoViewerState = useSelector((state) => state.photoViewer);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft" || e.keyCode === 37) {
      dispatch(photoViewerActions.goToPrevious());
    } else if (e.key === "ArrowRight" || e.keyCode === 39) {
      dispatch(photoViewerActions.goToNext());
    } else if (e.key === "Backspace" || e.keyCode === 8) {
      dispatch(photoViewerActions.close())
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  if (!photoViewerState.isOpen) {
    return null;
  }

  const photo = photoViewerState.photos[photoViewerState.photoIndex];

  return (
    <Dialog
      fullScreen
      open={photoViewerState.isOpen}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
        onClick={() => dispatch(photoViewerActions.close())}
      >
        <CloseIcon
          sx={{
            color: "white",
          }}
        />
      </IconButton>
      <Grid
        container
        width="100%"
        height="100%"
        backgroundColor="black"
        sx={{
        }}
      >
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {photoViewerState.photoIndex > 0 && (
            <IconButton
              onClick={() => dispatch(photoViewerActions.goToPrevious())}
            >
              <ArrowCircleLeftIcon
                sx={{
                  color: "white"
                }}
              />
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          xs={10}
          height="100%"
          sx={{
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          ref={imageContainerRef}
        >
          <ScreenScalingImage 
            imageURL={photo.url} 
            parentRef={imageContainerRef}
            borderColor="white"
            borderWidth={isXs ? 5 : 20}
          />
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {photoViewerState.photoIndex < photoViewerState.photos.length - 1 && (
            <IconButton
              onClick={() => dispatch(photoViewerActions.goToNext())}
            >
              <ArrowCircleRightIcon
                sx={{
                  color: "white"
                }}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}
