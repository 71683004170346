import React from "react";
import VSlideContainer from "components/VSlideContainer";
import LandingSection from "components/LandingSection";
import PhotosSection from "components/PhotosSection";
import { useSelector, useDispatch } from "react-redux";
import useInterval from "hooks/useInterval";
import { scrollActions } from "store/scroll";
import AboutSection from "components/AboutSection";

export default function HomeView() {

  const dispatch = useDispatch();
  const scroll = useSelector((state) => state.scroll);

  useInterval(() => {
    if (!scroll.target) return;
    document.getElementById(scroll.target).scrollIntoView({ behavior: "smooth" });
    dispatch(scrollActions.clearTarget());
  }, 500);

  return (
    <VSlideContainer>
      <div id="landing-section" style={{width: "100%", height: "100%"}}>
        <LandingSection />
      </div>
      <div id="photos-section"  style={{width: "100%", height: "100%"}}>
        <PhotosSection />
      </div>
      <div id="about-section"  style={{width: "100%", height: "100%"}}>
        <AboutSection />
      </div>
    </VSlideContainer>
  );
}
