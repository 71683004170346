import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react";

function ScreenScalingImage({ imageURL, borderWidth, parentRef, imageBoxClassName, imageClassName }) {

  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [parentWidth, setParentWidth] = useState(0);
  const [parentHeight, setParentHeight] = useState(0);

  function getImageDimensions() {
    const img = new Image();

    img.onload = function () {
      const width = img.width;
      const height = img.height;
      setImageWidth(width);
      setImageHeight(height);
    };

    img.src = imageURL;
  }

  function getParentDimensions() {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setParentWidth(rect.width);
      setParentHeight(rect.height);
    }
  }

  useEffect(() => {
    getParentDimensions();
  }, [parentRef]);

  useEffect(() => {
    getImageDimensions();
  }, [imageURL]);

  const handleResize = () => {
    getParentDimensions();
  };

  useEffect(() => {
    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let displayWidth = 0;
  let displayHeight = 0;

  if (imageWidth > 0 && imageHeight > 0) {
    const widthRatio = parentWidth / imageWidth;
    const heightRatio = parentHeight / imageHeight;
    const minRatio = Math.min(widthRatio, heightRatio);
    displayWidth = imageWidth * minRatio - 2 * borderWidth;
    displayHeight = imageHeight * minRatio - 2 * borderWidth;
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className={imageBoxClassName}
      >
        <img
          src={imageURL}
          alt=""
          style={{
            width: displayWidth,
            height: displayHeight,
          }}
          className={imageClassName}
        />
      </div>
    </div>
  );
}

ScreenScalingImage.propTypes = {
  imageURL: PropTypes.string,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string,
  parentRef: PropTypes.any,
}

ScreenScalingImage.defaultProps = {
  borderColor: "white",
  borderWidth: 0,
}

export default ScreenScalingImage;