import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
}

const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    close(state) {
      state.isOpen = false;
    },
    open(state) {
      state.isOpen = true;
    },
    toggle(state) {
      state.isOpen = !state.isOpen;
    },
  }
});

export const menuActions = menuSlice.actions;

export default menuSlice.reducer;