import React from 'react'
import "css/Menu.css";
import { useSelector, useDispatch } from 'react-redux';
import {
  Stack,
  Dialog,
  Typography,
  ListItem,
  ListItemButton,
  List,
  ListItemText,
} from "@mui/material";
import {
  useNavigate,
} from "react-router-dom";
import Me from 'images/Me';
import InstagramButton from './InstagramButton';
import { menuActions } from 'store/menu';
import { photoViewerActions } from 'store/photoViewer';
import { scrollActions } from 'store/scroll';

export default function Menu() {

  const dispatch = useDispatch();

  const menuState = useSelector((state) => state.menu);
  const navigate = useNavigate();

  const go = (path, scrollTarget = null) => {
    dispatch(scrollActions.setTarget(scrollTarget));
    dispatch(photoViewerActions.close());
    dispatch(menuActions.close());
    navigate(path);
  }

  return (
    <Dialog
      fullScreen
      open={menuState.isOpen}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
        }}
        spacing={2}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => go("/", "landing-section")}
            >
              <ListItemText primary={
                <Typography
                  textTransform="capitalize"
                  textAlign="center"
                  fontFamily="'Anton', sans-serif"
                  color="black"
                >
                  Home
                </Typography>
              } />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => go("/", "photos-section")}
            >
              <ListItemText primary={
                <Typography
                  textTransform="capitalize"
                  textAlign="center"
                  fontFamily="'Anton', sans-serif"
                  color="black"
                >
                  Photos
                </Typography>
              } />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => go("/", "about-section")}
            >
              <ListItemText primary={
                <Typography
                  textTransform="capitalize"
                  textAlign="center"
                  fontFamily="'Anton', sans-serif"
                  color="black"
                >
                  About
                </Typography>
              } />
            </ListItemButton>
          </ListItem>
        </List>
        <Me />
        <Stack
          justifyContent="center"
          alignItems="center"
        >
          <InstagramButton color="black" />
          <Typography fontWeight="bold">@fran.a.photo</Typography>
        </Stack>
      </Stack>
    </Dialog>
  )
}
