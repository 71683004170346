export const filterByCategory = (photos, category) => {
  if(category === "all") return photos;
  return photos.filter((photo) => photo.category === category);
}

export const getCategoryMainPhotos = (photos) => {
  return photos.filter((photo) => photo.categoryIndex === 1).sort((x, y) => x.category < y.category);
}

export const sortByCategory = (photos, category) => {

  if(category === "all") {
    const photosByCategory = {};
    const categoryIndex = {};
    for(let i = 0; i < photos.length; i++) {
      const photo = photos[i];
      if(!(photo.category in photosByCategory)) {
        photosByCategory[photo.category] = [];
        categoryIndex[photo.category] = 0;
      }
      photosByCategory[photo.category].push(photo);
    }
    const categoryNames = Object.keys(photosByCategory).sort();
    categoryNames.forEach((category) => {
      photosByCategory[category] = photosByCategory[category].sort((x, y) => x.categoryIndex - y.categoryIndex);
    });
    const ret = [];
    while(true) {
      let found = false;
      categoryNames.forEach((category) => {
        const index = categoryIndex[category]
        if(index < photosByCategory[category].length) {
          ret.push(photosByCategory[category][index]);
          categoryIndex[category] += 1;
          found = true;
        }
      });
      if(!found) break;
    }
    return ret;
  } else {
    return photos.sort((x, y) => x.categoryIndex - y.categoryIndex);
  }
}