import React from "react";
import "css/ScrollArrow.css";

export default function ScrollArrow() {

  return (
    <div className="scroll-arrow"/>
  );

}
