import React, { useEffect, useState } from 'react';
import styles from 'css/MotionBlur.css'; // Import your CSS module
import ScrollArrow from './ScrollArrow';
import useInterval from "hooks/useInterval";
import {
  Stack, Typography,
} from "@mui/material";
import HScrollGallery from './HScrollGallery';
import MotionImage from './MotionImage';
import MotionTransition from "./MotionTransition";
import { storage } from "firebase-app";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { db } from "firebase-app";
import { doc } from "firebase/firestore";
import { ref, getDownloadURL } from 'firebase/storage';
import BlurLoader from 'loaders/BlurLoader';

const MotionBlur = () => {

  var windowHeight = window.innerHeight;

  const docRef = doc(db, "galleries", "motion");
  const [gallery, loadingGallery, error, reload] = useDocumentDataOnce(docRef);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [lastScrollMs, setLastScrollMs] = useState(0);
  const [showArrow, setShowArray] = useState(true);

  useInterval(() => {
    const currentTimeMs = new Date().getTime();
    setShowArray(scrollPercentage === 0 || (scrollPercentage < 1 && currentTimeMs - lastScrollMs > 1000));
  }, 100);

  const handleScroll = () => {
    const element = document.documentElement;
    const scrollTop = element.scrollTop;
    const percentScrolled = (scrollTop / (windowHeight));
    setScrollPercentage(percentScrolled);
    setLastScrollMs(new Date().getTime());
  }

  useEffect(() => {

    // Attach the event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run the effect only once

  const renderMotionBlurText = () => {
    if (scrollPercentage < 0.5) return null;
    return (
      <Stack
        className="motion-text-container"
        justifyContent="center"
        alignItems="center"
        p={5}
      >
        <Typography
          fontFamily="'Archivo Black', sans-serif"
          fontSize="3rem"
          textTransform="uppercase"
          color="white"
          className="motion-word"
          textAlign="center"
        >
          Shadows of
        </Typography>
        <Typography
          fontFamily="'Archivo Black', sans-serif"
          fontSize="3rem"
          textTransform="uppercase"
          color="white"
          className="motion-word"
          textAlign="center"
        >
          Taiwan
        </Typography>
      </Stack>
    );
  }

  const renderArrow = () => {
    if (!showArrow) return null;
    return (
      <Stack
        className="motion-blue-text-container"
        direction="row"
        sx={{
          position: "fixed",
          bottom: "50px",
          width: "100vw",
        }}
        justifyContent="center"
        alignItems="center"

      >
        <ScrollArrow />
      </Stack>
    );
  }

  const renderPhotos = () => {
    if(!gallery) return null;
    return (
      gallery.photos.map((photo, index) => (
        <MotionImage 
          key={index} 
          photos={gallery.photos} 
          photo={photo} 
          index={index} 
        />
      ))
    );
  }

  if(loadingGallery) {
    return (
      <Stack
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <BlurLoader />
      </Stack>
    )
  }

  return (
    <>
      <div
        className="motion-container"
        style={{
          height: 2 * windowHeight,
        }}
      >
        <div className="motion-base-image" />
        <div
          className="motion-blur-image"
          style={{
            opacity: scrollPercentage,
          }}
        />
        {renderArrow()}
        {renderMotionBlurText()}
      </div>
      <MotionTransition />
      {renderPhotos()}
    </>
  );
};

export default MotionBlur;
