
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from "firebase/storage";
import firebaseConfig from './firebaseConfig';


const isDevMode = window.location.hostname === "localhost" && false;

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

function snapshotToArray(snapshot) {
  var arr = [];
  snapshot.forEach((child) => {
    arr.push({...child.data(), id: child.id});
  });
  return arr;
}


if(isDevMode) {
  // Setup emulator usage
  //connectFirestoreEmulator(db, "localhost", 8080);
  //connectFunctionsEmulator(functions, "localhost", 5001);
}

export {
  auth,
  db,
  functions,
  storage,
  snapshotToArray,
};
