import React from "react";
import {
  Stack, Typography,
} from "@mui/material";

export default function MotionTransition({ image }) {
  return (
    <Stack
      sx={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "50%",
          backgroundColor: "black",
        }}
      >

      </Stack>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "50%",
          backgroundColor: "white",
          zIndex: 1,
        }}
      >
       <Typography
        fontSize="3vh"
        fontFamily="'Archivo Black', sans-serif"
        textAlign="center"
        sx={{
          padding: "50px",
          zIndex: 1,
        }}
       >
        Images created in camera by combining long exposure with intentional camera movement
       </Typography>
      </Stack>
    </Stack>
  )
}
